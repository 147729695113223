import React, { useContext, useEffect, useState } from "react";
import FinancialTable from "../../../components/financeTable/FinancialTable";
import { exportToPDF } from "../../../utils/exportToPdf/exportToPDF";
import axios from "../../../api/axios";
import Flatpickr from "react-flatpickr"; // Import Flatpickr
import { SettingsContext } from "../../../context/settings";

export default function IncomeStatement() {
  const [incomeStatementData, setIncomeStatementData] = useState(null);
  const [dateRange, setDateRange] = useState([]); // Date range selected by user
  const settings = useContext(SettingsContext);
  const fetchIncomeStatement = async (startDate, endDate) => {
    try {
      let params = {};
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      const response = await axios.get("/income-statement", { params });
      setIncomeStatementData(response.data);
    } catch (error) {
      console.error("Error fetching income statement:", error);
    }
  };

  useEffect(() => {
    fetchIncomeStatement();
  }, []);

  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);

    const startDate = selectedDates[0]
      ? selectedDates[0].toISOString().split("T")[0]
      : null;
    const endDate = selectedDates[1]
      ? selectedDates[1].toISOString().split("T")[0]
      : new Date().toISOString().split("T")[0];

    fetchIncomeStatement(startDate, endDate);
  };

  // Define the headers and subheaders for the table

  // Format the data for the table

  const handleDownload = () => {
    const companyDetails = {
      name: settings.application_name || "",
      address: settings.application_address || "",
      logo: settings.application_logo,
    };
    exportToPDF(incomeStatementData, companyDetails);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <h2>Income Statement</h2>
        <button className="btn btn-primary" onClick={handleDownload}>
          Download PDF
        </button>
      </div>

      <div className="form-group mb-3">
        <label>Filter by Date Range</label>
        <div className="form-control p-0">
          <Flatpickr
            options={{ mode: "range", dateFormat: "Y-m-d" }}
            value={dateRange}
            onChange={handleDateChange}
            className="form-control"
            placeholder="Select Date Range"
          />
        </div>
      </div>

      {incomeStatementData ? (
        <FinancialTable
          data={incomeStatementData}
          // columns={columns}
          // title="Income Statement"
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
