import React, { useState } from "react";

// export default function FinancialTable({ data, columns, title }) {
//   const [expandedRows, setExpandedRows] = useState({});

//   // Toggle expand/collapse for a given row
//   const toggleRow = (label) => {
//     setExpandedRows((prevExpanded) => ({
//       ...prevExpanded,
//       [label]: !prevExpanded[label],
//     }));
//   };

//   return (
//     <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
//       <div className="card-header">
//         <h3 className="card-title">{title}</h3>
//       </div>
//       <div className="card-body">
//         <table className="table table-hover table-bordered">
//           <thead className="table-light">
//             <tr>
//               {columns.map((col, idx) => (
//                 <th key={idx} className="text-center fw-bold">
//                   {col}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {data.length > 0 ? (
//               data.map((row, idx) => {
//                 // Determine if this row is a breakdown item based on parent property
//                 const isBreakdownItem = row.parent;
//                 const isExpanded = expandedRows[row.parent];

//                 // Render main row or breakdown items conditionally
//                 return (
//                   (!isBreakdownItem || isExpanded) && (
//                     <tr
//                       key={idx}
//                       onClick={() => row.isExpandable && toggleRow(row.label)}
//                       style={{
//                         cursor: row.isExpandable ? "pointer" : "default",
//                       }}
//                     >
//                       <td className="text-center">
//                         {row.isExpandable ? (
//                           <span>
//                             {expandedRows[row.label] ? "▼ " : "► "}
//                             {row.label}
//                           </span>
//                         ) : (
//                           row.label
//                         )}
//                       </td>
//                       <td className="text-center">{row.amount}</td>
//                     </tr>
//                   )
//                 );
//               })
//             ) : (
//               <tr>
//                 <td colSpan={columns.length} className="text-center">
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// }

export default function RevenueExpensesTable({ data }) {
  const { revenue, expenses, totalRevenue, totalExpenses, netIncome } = data;
  function formatString(input) {
    return input
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }

  return (
    <div style={{ margin: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Revenue and Expenses
      </h2>

      {/* Revenue Table */}
      <h3>Revenue</h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
        className="table table-bordered"
      >
        <thead>
          <tr style={{ borderBottom: "2px solid #000" }}>
            <th style={{ padding: "10px", textAlign: "left" }}>Category</th>
            <th style={{ padding: "10px", textAlign: "right" }}>
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {revenue.map((item) => (
            <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "10px" }}>{formatString(item._id)}</td>
              <td style={{ padding: "10px", textAlign: "right" }}>
                ৳{item.totalRevenue.toLocaleString()}
              </td>
            </tr>
          ))}
          <tr style={{ fontWeight: "bold" }}>
            <td style={{ padding: "10px" }}>Total Revenue</td>
            <td style={{ padding: "10px", textAlign: "right" }}>
              ৳{totalRevenue.toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Expenses Table */}
      <h3>Expenses</h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
        className="table table-bordered"
      >
        <thead>
          <tr style={{ borderBottom: "2px solid #000" }}>
            <th style={{ padding: "10px", textAlign: "left" }}>Category</th>
            <th style={{ padding: "10px", textAlign: "right" }}>
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {expenses.map((item) => (
            <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "10px" }}>{formatString(item._id)}</td>
              <td style={{ padding: "10px", textAlign: "right" }}>
                ৳{item.totalExpenses.toLocaleString()}
              </td>
            </tr>
          ))}
          <tr style={{ fontWeight: "bold" }}>
            <td style={{ padding: "10px" }}>Total Expenses</td>
            <td style={{ padding: "10px", textAlign: "right" }}>
              ৳{totalExpenses.toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Net Income */}
      <div
        style={{ textAlign: "right", fontWeight: "bold", fontSize: "1.2em" }}
      >
        <p>
          Net Income:{" "}
          <span style={{ color: "green" }}>৳{netIncome.toLocaleString()}</span>
        </p>
      </div>
    </div>
  );
}
