import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function formatString(input) {
  return input
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}

export const exportToPDF = (data, companyDetails = {}) => {
  if (!data) return null;

  const doc = new jsPDF();

  // Set global font and font size
  doc.setFont("helvetica");
  doc.setFontSize(12);

  // Add Company Logo, Name, and Details
  const {
    name = "Company Name",
    address = "Company Address",
    logo,
  } = companyDetails;

  if (logo) {
    doc.addImage(
      `${process.env.REACT_APP_API_ENDPOINT}/uploads/${logo}`,
      "PNG",
      14,
      10,
      30,
      30
    );
  }
  doc.setFontSize(18).setFont("helvetica", "bold");
  doc.text(name, logo ? 50 : 14, 20);
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(address, logo ? 50 : 14, 28);
  doc.text(`Date: ${new Date().toLocaleDateString()}`, logo ? 50 : 14, 35);

  // Report Title
  doc.setFontSize(14).setFont("helvetica", "bold");
  doc.text("Financial Report", 14, 50);

  // Revenue Table
  doc.setFontSize(12).text("Revenue", 14, 60);
  autoTable(doc, {
    startY: 65,
    head: [["Category", "Total Amount", "Total Revenue"]],
    body: data.revenue.map((item) => [
      formatString(item._id),
      `BDT ${item.categories[0].totalAmount.toLocaleString()}`,
      `BDT ${item.totalRevenue.toLocaleString()}`,
    ]),
    theme: "striped",
    headStyles: {
      fillColor: [22, 160, 133],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    styles: { halign: "center" },
  });

  // Expenses Table
  doc.setFontSize(12).text("Expenses", 14, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 15,
    head: [["Category", "Total Amount", "Total Expenses"]],
    body: data.expenses.map((item) => [
      formatString(item._id),
      `BDT ${item.categories[0].totalAmount.toLocaleString()}`,
      `BDT ${item.totalExpenses.toLocaleString()}`,
    ]),
    theme: "striped",
    headStyles: {
      fillColor: [192, 57, 43],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    styles: { halign: "center" },
  });

  // Summary Section
  doc.setFontSize(12).text("Summary", 14, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 15,
    body: [
      ["Total Revenue", `BDT ${data.totalRevenue?.toLocaleString()}`],
      ["Total Expenses", `BDT ${data.totalExpenses?.toLocaleString()}`],
      ["Net Income", `BDT ${data.netIncome?.toLocaleString()}`],
    ],
    theme: "grid",
    headStyles: {
      fillColor: [41, 128, 185],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    bodyStyles: { textColor: [44, 62, 80], fontStyle: "normal" },
    styles: { halign: "center" },
  });

  // Save the PDF with a relevant title
  doc.save("Financial_Report.pdf");
};
