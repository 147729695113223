import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/flatpickr.css";
import Select from "react-select";
import { AuthContext } from "../../context/auth";

export const LoanAdd = () => {
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    accountName: "",
    accountNumber: "",
    note: "",
    openDate: null,
    effectiveDate: null,
    expireDate: null,
    loanAmount: "",
    interestRate: "",
    status: "",
  });

  const [dateRange, setDateRange] = useState([null, null]);
  const [loading, setLoading] = useState(false);

  // Handle form data input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle Date range changes for open, effective, and expire dates
  const handleDateChange = (name, selectedDate) => {
    setFormData({
      ...formData,
      [name]: selectedDate,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post("/add-loan", formData);
      toast.success("Loan added successfully");
      setFormData({
        accountName: "",
        accountNumber: "",
        note: "",
        openDate: null,
        effectiveDate: null,
        expireDate: null,
        loanAmount: "",
        interestRate: "",
        status: "",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error adding loan:", error);
      toast.error("Failed to add loan");
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow p-3 mb-5 bg-body rounded">
            <div className="card-header">
              <h3 className="card-title">Add Loan</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="accountName">Account Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="accountName"
                    name="accountName"
                    value={formData.accountName}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="accountNumber">Account Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="accountNumber"
                    name="accountNumber"
                    value={formData.accountNumber}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="note">Note</label>
                  <textarea
                    className="form-control"
                    id="note"
                    name="note"
                    value={formData.note}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>

                <div className="form-group">
                  <label htmlFor="loanAmount">Loan Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="loanAmount"
                    name="loanAmount"
                    value={formData.loanAmount}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="interestRate">Daily Interest Rate (%)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="interestRate"
                    name="interestRate"
                    value={formData.interestRate}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <Select
                    options={[
                      { value: "active", label: "Active" },
                      { value: "deactive", label: "Deactive" },
                    ]}
                    value={
                      formData.status
                        ? { value: formData.status, label: formData.status }
                        : null
                    }
                    onChange={(selectedOption) =>
                      setFormData({ ...formData, status: selectedOption.value })
                    }
                    placeholder="Select Status"
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Open Date</label>
                  <Flatpickr
                    value={formData.openDate}
                    onChange={(date) => handleDateChange("openDate", date[0])}
                    className="form-control"
                    placeholder="Select Open Date"
                  />
                </div>

                <div className="form-group">
                  <label>Effective Date</label>
                  <Flatpickr
                    value={formData.effectiveDate}
                    onChange={(date) =>
                      handleDateChange("effectiveDate", date[0])
                    }
                    className="form-control"
                    placeholder="Select Effective Date"
                  />
                </div>

                <div className="form-group">
                  <label>Expire Date</label>
                  <Flatpickr
                    value={formData.expireDate}
                    onChange={(date) => handleDateChange("expireDate", date[0])}
                    className="form-control"
                    placeholder="Select Expire Date"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Add Loan"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
