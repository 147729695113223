import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

const TopSalesmanRecentMonth = () => {
  const [topSalesmen, setTopSalesmen] = useState([]);
  const [previousTopSalesmen, setPreviousTopSalesmen] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [userData, setUserData] = useState([]);

  const itemsPerPage = 15;

  useEffect(() => {
    fetchTopSellers();
    fetchUserData();
  }, []);

  const fetchTopSellers = async () => {
    try {
      // Fetch all sales data
      const sellResponse = await axios.get("/get-month-sell");

      const sellData = sellResponse.data;

      // Aggregate sales data by seller
      const salesBySeller = sellData.reduce((accumulator, current) => {
        const { seller_id, total_price } = current;
        accumulator[seller_id] = (accumulator[seller_id] || 0) + total_price;
        return accumulator;
      }, {});

      // Sort sellers by total sales
      const sortedSellers = Object.keys(salesBySeller).sort(
        (a, b) => salesBySeller[b] - salesBySeller[a]
      );

      // Get the top 3 sellers
      const topSellerIds = sortedSellers.slice(0, 3);

      // Create seller objects with IDs only
      const topSellers = topSellerIds.map((sellerId) => ({
        id: sellerId,
        sales: salesBySeller[sellerId],
      }));

      // Compare with previous top sellers
      if (JSON.stringify(topSellers) !== JSON.stringify(previousTopSalesmen)) {
        updateArrowDirection(topSellers);
        setPreviousTopSalesmen(topSellers);
      }

      setTopSalesmen(topSellers);
    } catch (error) {
      console.error("Error fetching top sellers:", error);
    }
  };

  const fetchUserData = async () => {
    try {
      const userResponse = await axios.get(
        `/get-user?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setUserData(userResponse.data?.userLists || []);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updateArrowDirection = (newTopSellers) => {
    newTopSellers.forEach((seller, index) => {
      const previousTopSeller = previousTopSalesmen[index];
      if (previousTopSeller && seller.id !== previousTopSeller.id) {
        if (index === 0) {
          seller.arrow = "up";
          previousTopSeller.arrow = "down";
        } else if (index === 1) {
          seller.arrow = "up";
          previousTopSeller.arrow = "down";
        } else if (index === 2) {
          seller.arrow = "up";
          previousTopSeller.arrow = "down";
        }
      } else {
        seller.arrow = ""; // No change in position, so no arrow
      }
    });
  };

  return (
    <div className="card">
      <div className="card-header">Top Salesmen of Recent Month</div>
      <div className="card-body">
        <ul className="list-group">
          {topSalesmen && topSalesmen?.length > 0 ? (
            topSalesmen.map((salesman, index) => {
              const username =
                userData?.find((user) => user.user_id === salesman.id)
                  ?.username || "Unknown";

              return (
                <li
                  key={index}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div>
                    Seller: <span className="font-weight-bold">{username}</span>
                    , Sales: {salesman.sales} BDT
                  </div>
                  {salesman.arrow === "up" ? (
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="text-success"
                    />
                  ) : salesman.arrow === "down" ? (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      className="text-danger"
                    />
                  ) : null}
                </li>
              );
            })
          ) : (
            <p>No data available</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default TopSalesmanRecentMonth;
