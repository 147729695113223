import React, { useContext, useEffect, useState } from "react";
import RevenueExpensesTable from "../../../components/financeTable/RevenueExpensesTable";
import { exportBalanceSheetToPDF } from "../../../utils/exportToPdf/exportToPdfBalanceSheet";
import axios from "../../../api/axios";
import companyLogo from "../../../layout/img/emgLogo-1.png";
import { SettingsContext } from "../../../context/settings";

export default function BalanceSheet() {
  const [balanceSheetData, setBalanceSheetData] = useState([]);
  const settings = useContext(SettingsContext);

  const fetchBalanceSheet = async () => {
    try {
      const response = await axios.get("/balance-sheet");

      if (response.data) {
        // Structure data with breakdowns for assets and liabilities
        const formattedData = [
          {
            label: "Total Assets",
            amount: response.data.totalAssets,
            isExpandable: true,
          },
          ...response.data.assetBreakdown.map((asset) => ({
            label: `- ${asset.subCategory || "Uncategorized"}`,
            amount: asset.amount,
            parent: "Total Assets",
          })),
          {
            label: "Total Liabilities",
            amount: response.data.totalLiabilities,
            isExpandable: true,
          },
          ...response.data.liabilityBreakdown.map((liability) => ({
            label: `- ${liability.subCategory || "Uncategorized"}`,
            amount: liability.amount,
            parent: "Total Liabilities",
          })),
          { label: "Equity", amount: response.data.equity },
        ];

        // Set formatted data to balanceSheetData state
        setBalanceSheetData(formattedData);
        console.log("Formatted Balance Sheet Data:", formattedData); // Confirm the structure
      }
    } catch (error) {
      console.error("Error fetching balance sheet:", error);
    }
  };

  useEffect(() => {
    fetchBalanceSheet();
  }, []);

  const columns = ["Label", "Amount"];

  const handleBalanceSheetDownload = () => {
    const companyDetails = {
      logo: companyLogo,
      name: "Euro Marble & Granite",
      address: "6/7 West Dhirashram, Gazipur Sadar, Gazipur - 1700",
      date: new Date().toLocaleDateString(),
    };

    const footerNotes = `
    Disclaimer:
    - This balance sheet is prepared based on available data.
    `;

    exportBalanceSheetToPDF(
      "balanceSheet",
      "Balance Sheet",
      balanceSheetData,
      columns,
      companyDetails,
      footerNotes
    );
  };

  const downloadBalanceSheet = () => {
    if (!balanceSheetData || balanceSheetData.length === 0) {
      console.error("Balance sheet data is empty or not properly formatted.");
      return;
    }

    const companyDetails = {
      name: settings.application_name || "",
      address: settings.application_address || "",
      logo: settings.application_logo,
    };

    exportBalanceSheetToPDF(balanceSheetData, companyDetails, "Balance Sheet");
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <h2>Baance Sheet</h2>
        <button className="btn btn-primary" onClick={downloadBalanceSheet}>
          Download PDF
        </button>
      </div>

      <RevenueExpensesTable
        data={balanceSheetData}
        columns={columns}
        title="Balance Sheet"
      />
    </div>
  );
}
