import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../../api/axios";
import { toast } from "react-toastify";

const UpdateExternalInventoryModal = ({ inventory, show, onClose }) => {
  const [updatedInventory, setUpdatedInventory] = useState({
    externalinventory_id: "",
    client_name: "",
    product_name: "",
    init_product_width: "",
    init_product_length: "",
    init_height: "",
    weight: "",
    quantity: "",
    status: "",
  });

  useEffect(() => {
    if (inventory) {
      setUpdatedInventory({
        externalinventory_id: inventory.externalinventory_id,
        client_name: inventory.client_name,
        product_name: inventory.product_name,
        init_product_width: inventory.init_product_width,
        init_product_length: inventory.init_product_length,
        init_height: inventory.init_height,
        weight: inventory.weight,
        quantity: inventory.quantity,
        status: inventory.status,
      });
    }
  }, [inventory]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedInventory({
      ...updatedInventory,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `/update-account-inventory`,
        updatedInventory
      );
      toast.success("Inventory updated successfully");
      onClose();
    } catch (error) {
      console.error("Error updating inventory:", error);
      toast.error("Failed to update inventory");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit External Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="externalinventory_id">Inventory ID</label>
            <input
              type="text"
              className="form-control"
              id="externalinventory_id"
              name="externalinventory_id"
              value={updatedInventory.externalinventory_id}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="client_name">Client Name</label>
            <input
              type="text"
              className="form-control"
              id="client_name"
              name="client_name"
              value={updatedInventory.client_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="product_name">Product Name</label>
            <input
              type="text"
              className="form-control"
              id="product_name"
              name="product_name"
              value={updatedInventory.product_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_product_width">Product Width</label>
            <input
              type="number"
              className="form-control"
              id="init_product_width"
              name="init_product_width"
              value={updatedInventory.init_product_width}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_product_length">Product Length</label>
            <input
              type="number"
              className="form-control"
              id="init_product_length"
              name="init_product_length"
              value={updatedInventory.init_product_length}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_height">Product Height</label>
            <input
              type="number"
              className="form-control"
              id="init_height"
              name="init_height"
              value={updatedInventory.init_height}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="weight">Product Weight</label>
            <input
              type="number"
              className="form-control"
              id="weight"
              name="weight"
              value={updatedInventory.weight}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              value={updatedInventory.quantity}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              className="form-control"
              id="status"
              name="status"
              value={updatedInventory.status}
              onChange={handleInputChange}
              required
            >
              <option value="Pending">Pending</option>
              <option value="Processing">Processing</option>
              <option value="Completed">Completed</option>
              <option value="Delivered">Delivered</option>
            </select>
          </div>

          <Button variant="primary" type="submit">
            Update Inventory
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateExternalInventoryModal;
