import React, { useEffect, useState } from "react";
import FinancialTable from "../../../components/financeTable/FinancialTable";
import { exportToPDF } from "../../../utils/exportToPdf/exportToPDF";
import axios from "../../../api/axios";
import companyLogo from "../../../layout/img/emgLogo-1.png";

export default function CashFlowStatement() {
  const [cashFlowData, setCashFlowData] = useState([]);

  const fetchCashFlow = async () => {
    try {
      const response = await axios.get("/cash-flow");
      setCashFlowData(response.data);
    } catch (error) {
      console.error("Error fetching cash flow:", error);
    }
  };

  useEffect(() => {
    fetchCashFlow();
  }, []);

  const columns = ["Cash Inflows", "Cash Outflows", "Net Cash Flow"];

  const handleCashFlowDownload = () => {
    const companyDetails = {
      logo: companyLogo, // Your logo
      name: "Euro Marble & Granite",
      address: "6/7 West Dhirashram, Gazipur Sadar, Gazipur - 1700",
      date: new Date().toLocaleDateString(), // Current date
    };

    const footerNotes = `
    Notes:
    - Cash flow report prepared based on operational data.
  `;

    exportToPDF(
      "cashFlow",
      "Cash Flow Statement",
      cashFlowData, // Pass the cash flow data here
      ["Inflow", "Outflow", "Net Cash Flow"],
      companyDetails,
      footerNotes
    );
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between mb-3">
        <h2>Cash Flow Statement</h2>
        <button className="btn btn-primary" onClick={handleCashFlowDownload}>
          Download PDF
        </button>
      </div>
      <FinancialTable
        data={[cashFlowData]}
        columns={columns}
        title="Cash Flow Statement"
      />
    </div>
  );
}
