import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const UpdateBlockModal = ({ block, show, onClose }) => {
  const [updatedBlock, setUpdatedBlock] = useState({
    block_id: "",
    category_id: "",
    block_name: "",
    init_height: "",
    init_width: "",
    init_length: "",
    init_weight: "",
    parent_id: "",
    // childs: "",
    // damaged_time: "",
    damaged_note: "",
    user_id: "",
    requisition_id: "",
    purchase_id: "",
    vendor_id: "",
    quantity: "",
  });

  // Populate the state with the current block values when available
  useEffect(() => {
    if (block) {
      setUpdatedBlock({
        block_id: block.block_id || "",
        category_id: block.category_id || "",
        block_name: block.block_name || "",
        init_height: block.init_height || "",
        init_width: block.init_width || "",
        init_length: block.init_length || "",
        init_weight: block.init_weight || "",
        parent_id: block.parent_id || "",
        // childs: block.childs || "",
        // damaged_time: block.damaged_time || "",
        damaged_note: block.damaged_note || "",
        user_id: block.user_id || "",
        requisition_id: block.requisition_id || "",
        purchase_id: block.purchase_id || "",
        vendor_id: block.vendor_id || "",
        quantity: block.quantity || "",
      });
    }
  }, [block]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedBlock({
      ...updatedBlock,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update block
      await axios.put("/update-block", updatedBlock);
      onClose();
      toast.success("Block updated successfully");
    } catch (error) {
      console.error("Error updating block:", error);
      toast.error("Failed to update block");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Block</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="block_id">Block ID</label>
            <input
              type="text"
              className="form-control"
              id="block_id"
              name="block_id"
              value={updatedBlock.block_id}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="category_id">Category ID</label>
            <input
              type="text"
              className="form-control"
              id="category_id"
              name="category_id"
              value={updatedBlock.category_id}
              onChange={handleInputChange}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="block_name">Block Name</label>
            <input
              type="text"
              className="form-control"
              id="block_name"
              name="block_name"
              value={updatedBlock.block_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_height">Initial Height</label>
            <input
              type="number"
              className="form-control"
              id="init_height"
              name="init_height"
              value={updatedBlock.init_height}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_width">Initial Width</label>
            <input
              type="number"
              className="form-control"
              id="init_width"
              name="init_width"
              value={updatedBlock.init_width}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_length">Initial Length</label>
            <input
              type="number"
              className="form-control"
              id="init_length"
              name="init_length"
              value={updatedBlock.init_length}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="init_weight">Initial Weight</label>
            <input
              type="number"
              className="form-control"
              id="init_weight"
              name="init_weight"
              value={updatedBlock.init_weight}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              value={updatedBlock.quantity}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="damaged_note">Damaged Note</label>
            <textarea
              className="form-control"
              id="damaged_note"
              name="damaged_note"
              value={updatedBlock.damaged_note}
              onChange={handleInputChange}
            />
          </div>

          {/* Add additional fields as required */}
          <Button variant="primary" type="submit">
            Update Block
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateBlockModal;
