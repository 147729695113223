import React, { useContext, useEffect, useState } from "react";
import "./Invoice.css";
import { SettingsContext } from "../context/settings";
import companyLogo from "./img/emgLogo-1.png";
import { convertToWords } from "../utils/functions";

export const Invoice = ({ data }) => {
  const settings = useContext(SettingsContext);
  const [formattedData, setFormattedData] = useState([]);
  const [totals, setTotals] = useState({ totalPcs: 0, totalArea: 0 });
  const [discountPercentage, setDiscountPercentage] = useState(0);

  useEffect(() => {
    if (data) {
      try {
        const productIdList = data.product_id;
        const productNameList = data.product_name;
        const priceList = data.price;
        const quantityList = data.quantity;
        const custom_widthList = data.custom_width;
        const custom_lengthList = data.custom_length;
        const totalPrice = data.total_price;
        const discountAmount = data.discount_amount;

        let productList = [];
        let totalPcs = 0;
        let totalArea = 0;
        let percentage = (discountAmount / totalPrice) * 100;
        setDiscountPercentage(percentage.toFixed(2));

        for (let i = 0; i < productIdList.length; i++) {
          const widthInInches = (custom_widthList[i] || 0) * 0.0393701;
          const lengthInInches = (custom_lengthList[i] || 0) * 0.0393701;
          const areaSqM =
            ((custom_widthList[i] || 1) *
              (custom_lengthList[i] || 1) *
              quantityList[i]) /
            1000000;
          const areaSqFt = areaSqM * 10.7639;

          const upd = {
            id: productIdList[i],
            name: productNameList[i],
            price: priceList[i],
            quantity: quantityList[i],
            size: `${widthInInches.toFixed(2)} x ${lengthInInches.toFixed(2)}`,
            total: totalPrice,
            area: areaSqFt.toFixed(2),
            itemTotal: (priceList[i] * quantityList[i]).toFixed(2),
          };
          productList.push(upd);
          totalPcs += parseInt(quantityList[i], 10);
          totalArea += areaSqFt;
        }
        setFormattedData(productList);
        setTotals({ totalPcs, totalArea: totalArea.toFixed(2) });
      } catch (err) {
        console.log(err);
      }
    }
  }, [data]);
  return (
    <>
      <style>
        {`.invoice-container {
    width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
    p {
      margin-top: 0px;
      margin-bottom: 5px;
    }

  .invoice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 2px solid #000;
  }

  .company-logo {
    width: 100px;
  }

  .company-info {
    flex-grow: 1;
    text-align: center;
    font-size: 13px
  }

  .company-name {
    font-size: 24px;
    font-weight: bold;
  }

  .company-address {
    margin-top: 0;
  }

  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    min-height: 400px;
    display: block;
    font-size: 12px;
  }

  .invoice-table th,
  .invoice-table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
  }

  .thin-column {
    width: 50px;
  }

  .invoice-footer {
    margin-top: 0px;
  }

  .signature-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
  }

  .signature {
    text-align: center;
    width: 40%;
  }
  .signature p {
    border-top: 1px solid #000;
    line-height: 26px;
    font-size: 12px;
  }

  .company-policy {
    text-align: left;
    width: 100%;
    border-top: 2px solid #000;
    margin-bottom: 60px;
    font-size: 13px;
  }
  .company-policy ul{
    padding-left: 0px;
  }
  .totalPriceClass{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .totalPriceClass h5{
    padding: 5px;
    margin-bottom: 0px;
  }
  .customer_info {
        width: 70%;
    padding-right: 5px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin: 10px 0;
  }
  
  
    .invoiceInfo{
        width: 30%;
    }
    .detailsCalculation{
            margin-top: 0px;
            justify-content: flex-end;
            display: flex;
                font-size: 13px;

        }
  `}
      </style>
      <div className="invoice-container">
        <div className="invoice-header">
          <span className="brand-text fw-bold">
            <img
              src={
                settings.application_logo
                  ? `${process.env.REACT_APP_API_ENDPOINT}/api/uploads/${settings.application_logo}`
                  : companyLogo
              }
              alt="Company Logo"
              className="company-logo"
            />
          </span>
          <div className="company-info">
            <span className="brand-text fw-bold">
              <strong>{settings.application_name || "ERP"} </strong>
            </span>
            <p className="company-address text-secondary">
              <span className="brand-text fw-bold">
                {settings.application_address || "ERP"}
              </span>{" "}
            </p>
            <strong className="company-address text-secondary">
              Sell Invoice
            </strong>
          </div>
        </div>
        <div className="row">
          <div className="customer_info">
            <p>
              <strong>Customer Name:</strong> &nbsp;{data.customer_name}
            </p>

            <p>
              <strong>Customer Phone:</strong> {data.customer_phone_number}
            </p>

            <p>
              <strong>Customer Address:</strong> &nbsp;{data.customer_address}
            </p>
          </div>
          <div className="invoiceInfo">
            <p>
              <strong>Date:</strong>{" "}
              {new Date(data.createdAt).toLocaleDateString()}
            </p>
            <div className="company-address text-secondary">
              <strong>Invoice Number:</strong>{" "}
              <span style={{ textWrap: "nowrap" }}>{data.sell_id}</span>
            </div>
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th className="thin-column text-secondary">Item No</th>
              <th className="text-secondary">Product</th>
              <th className="text-secondary">Size (inches)</th>
              <th className="text-secondary">No. of PCS</th>
              <th className="text-secondary">Quantity (sqft)</th>
              <th className="text-secondary">Price (৳)</th>
              <th className="text-secondary">Total (৳)</th>
            </tr>
          </thead>
          <tbody className="text-secondary">
            {formattedData.map((x, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{x.name}</td>
                <td style={{ textWrap: "nowrap" }}>{x.size}</td>
                <td>{x.quantity}</td>
                <td>{x.area}</td>
                <td>{x.price}</td>
                <td>{x.itemTotal}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="3">
                <strong>Totals</strong>
              </td>
              <td>
                <strong>{totals.totalPcs}</strong>
              </td>
              <td>
                <strong>{totals.totalArea}</strong>
              </td>
              <td></td>
              <td>
                <strong>{data.total_price.toFixed(2)} ৳</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="detailsCalculation">
          <p>
            <strong>Total Amount :</strong> &nbsp;{data.total_price.toFixed(2)}{" "}
            ৳
          </p>
        </div>
        <div className="detailsCalculation">
          <p>
            <strong>Discount Amount :</strong> &nbsp; ({discountPercentage}%){" "}
            {data.discount_amount.toFixed(2)} ৳
          </p>
        </div>
        <div className="detailsCalculation">
          <p>
            <strong>Carrying Charge :</strong> {data.carring_charge.toFixed(2)}{" "}
            ৳
          </p>
        </div>

        <div className="totalPriceClass">
          <h5>
            Total Amount in words:{" "}
            <span style={{ fontWeight: "normal" }}>
              {convertToWords(data.total_price_after_discount)} Taka only.
            </span>{" "}
          </h5>
          <h5>
            Grand Total Price : {data.total_price_after_discount.toFixed(2)} ৳
          </h5>
        </div>

        <div className="invoice-footer text-secondary">
          <div className="company-policy">
            <ul>
              <li>
                50% payment at the time of order, 100% payment before delivery.
              </li>
              <li>
                Marble & granite are the product of nature, subject to variation
                & veins are accepted.
              </li>
              <li>Goods are dispatched at buyer's risk & cost</li>
            </ul>
          </div>
          <div className="signature-container">
            <div className="signature mt-5">
              <p>Customer's Acceptance Signature</p>
            </div>

            <div className="signature mt-5">
              <p>For Company Signature</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
