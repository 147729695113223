export default function RevenueExpensesTable({ data }) {
  // Ensure the data is structured for assets and liabilities
  const assets = Array.isArray(data)
    ? data.filter(
        (item) =>
          item.label === "Total Assets" || item.parent === "Total Assets"
      )
    : [];
  const liabilities = Array.isArray(data)
    ? data.filter(
        (item) =>
          item.label === "Total Liabilities" ||
          item.parent === "Total Liabilities"
      )
    : [];

  // Handle `Equity` based on the structure of `data`
  const equity = Array.isArray(data)
    ? data.find((item) => item.label === "Equity")?.amount || 0
    : data?.equity || 0; // Access `equity` directly if `data` is an object

  return (
    <div style={{ margin: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Balance Sheet
      </h2>

      {/* Assets Table */}
      <h3>Assets</h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
        className="table table-bordered"
      >
        <thead>
          <tr style={{ borderBottom: "2px solid #000" }}>
            <th style={{ padding: "10px", textAlign: "left" }}>Category</th>
            <th style={{ padding: "10px", textAlign: "right" }}>
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {assets.map((item, index) => (
            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "10px" }}>{item.label}</td>
              <td style={{ padding: "10px", textAlign: "right" }}>
                ৳{item.amount.toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Liabilities Table */}
      <h3>Liabilities</h3>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
        className="table table-bordered"
      >
        <thead>
          <tr style={{ borderBottom: "2px solid #000" }}>
            <th style={{ padding: "10px", textAlign: "left" }}>Category</th>
            <th style={{ padding: "10px", textAlign: "right" }}>
              Total Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {liabilities.map((item, index) => (
            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
              <td style={{ padding: "10px" }}>{item.label}</td>
              <td style={{ padding: "10px", textAlign: "right" }}>
                ৳{item.amount.toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Equity */}
      <div
        style={{ textAlign: "right", fontWeight: "bold", fontSize: "1.2em" }}
      >
        <p>
          Equity:{" "}
          <span style={{ color: equity >= 0 ? "green" : "red" }}>
            ৳{equity.toLocaleString()}
          </span>
        </p>
      </div>
    </div>
  );
}
