import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { toast } from "react-toastify";

const UpdateInventoryModal = ({ inventory, show, onClose }) => {
  const [updatedInventory, setUpdatedInventory] = useState({
    inventory_id: "",
    block_id: "",
    vendor_id: "",
    vendor_name: "",
    product_name: "",
    user_id: "",
    init_slab_weidth: "",
    init_slab_length: "",
    requisition_id: "",
    purchase_id: "",
    return_id: "",
    quantity: "",
    category_name: "",
    total_sell: "",
    total_area_sell: "",
  });

  // Populate the state with the current inventory values when available
  useEffect(() => {
    if (inventory) {
      setUpdatedInventory({
        inventory_id: inventory.inventory_id || "",
        block_id: inventory.block_id || "",
        vendor_id: inventory.vendor_id || "",
        vendor_name: inventory.vendor_name || "",
        product_name: inventory.product_name || "",
        user_id: inventory.user_id || "",
        init_slab_weidth: inventory.init_slab_weidth || "",
        init_slab_length: inventory.init_slab_length || "",
        requisition_id: inventory.requisition_id || "",
        purchase_id: inventory.purchase_id || "",
        return_id: inventory.return_id || "",
        quantity: inventory.quantity || "",
        category_name: inventory.category_name || "",
        total_sell: inventory.total_sell || "",
        total_area_sell: inventory.total_area_sell || "",
      });
    }
  }, [inventory]);
  console.log(inventory);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedInventory({
      ...updatedInventory,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update inventory
      await axios.put("/update-inventory", updatedInventory);
      onClose();
      toast.success("Inventory updated successfully");
    } catch (error) {
      console.error("Error updating inventory:", error);
      toast.error("Failed to update inventory");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="inventory_id">Inventory ID</label>
            <input
              type="text"
              className="form-control"
              id="inventory_id"
              name="inventory_id"
              value={updatedInventory.inventory_id}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="block_id">Block ID</label>
            <input
              type="text"
              className="form-control"
              id="block_id"
              name="block_id"
              value={updatedInventory.block_id}
              onChange={handleInputChange}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="vendor_id">Vendor ID</label>
            <input
              type="text"
              className="form-control"
              id="vendor_id"
              name="vendor_id"
              value={updatedInventory.vendor_id}
              onChange={handleInputChange}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="vendor_name">Vendor Name</label>
            <input
              type="text"
              className="form-control"
              id="vendor_name"
              name="vendor_name"
              value={updatedInventory.vendor_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="product_name">Product Name</label>
            <input
              type="text"
              className="form-control"
              id="product_name"
              name="product_name"
              value={updatedInventory.product_name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              value={updatedInventory.quantity}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="category_name">Category Name</label>
            <input
              type="text"
              className="form-control"
              id="category_name"
              name="category_name"
              value={updatedInventory.category_name}
              onChange={handleInputChange}
              required
              disabled
            />
          </div>

          {/* Add additional fields as required */}
          <Button variant="primary" type="submit">
            Update Inventory
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateInventoryModal;
