import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

function formatString(input) {
  return input
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}

export const exportBalanceSheetToPDF = (data = [], companyDetails = {}) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Data is empty or not in the expected format.");
    return null;
  }

  const doc = new jsPDF();

  // Set global font and font size
  doc.setFont("helvetica");
  doc.setFontSize(12);

  // Add Company Logo, Name, and Details
  const {
    name = "Company Name",
    address = "Company Address",
    logo,
  } = companyDetails;

  if (logo) {
    doc.addImage(
      `${process.env.REACT_APP_API_ENDPOINT}/uploads/${logo}`,
      "PNG",
      14,
      10,
      30,
      30
    );
  }
  doc.setFontSize(18).setFont("helvetica", "bold");
  doc.text(name, logo ? 50 : 14, 20);
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(address, logo ? 50 : 14, 28);
  doc.text(`Date: ${new Date().toLocaleDateString()}`, logo ? 50 : 14, 35);

  // Report Title
  doc.setFontSize(14).setFont("helvetica", "bold");
  doc.text("Balance Sheet", 14, 50);

  // Filter and Prepare Data for Assets
  const assets = data.filter(
    (item) => item.label === "Total Assets" || item.parent === "Total Assets"
  );
  doc.setFontSize(12).text("Assets", 14, 60);
  autoTable(doc, {
    startY: 65,
    head: [["Category", "Amount"]],
    body: assets.map((item) => [
      item.label,
      `BDT ${item.amount.toLocaleString()}`,
    ]),
    theme: "striped",
    headStyles: {
      fillColor: [22, 160, 133],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    styles: { halign: "center" },
  });

  // Filter and Prepare Data for Liabilities
  const liabilities = data.filter(
    (item) =>
      item.label === "Total Liabilities" || item.parent === "Total Liabilities"
  );
  doc.setFontSize(12).text("Liabilities", 14, doc.lastAutoTable.finalY + 10);
  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 15,
    head: [["Category", "Amount"]],
    body: liabilities.map((item) => [
      item.label,
      `BDT ${item.amount.toLocaleString()}`,
    ]),
    theme: "striped",
    headStyles: {
      fillColor: [192, 57, 43],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    styles: { halign: "center" },
  });

  // Extract Equity
  const equityItem = data.find((item) => item.label === "Equity");
  const equityAmount = equityItem ? equityItem.amount : 0;

  // Summary Section for Equity
  const finalY = doc.lastAutoTable.finalY + 15;
  doc.setFontSize(12).setFont("helvetica", "normal");
  doc.text(`Equity: BDT ${equityAmount.toLocaleString()}`, 14, finalY);

  // Save the PDF with a relevant title
  doc.save("Balance_Sheet.pdf");
};
