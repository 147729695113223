import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import axios from "../../../api/axios";
import Select from "react-select";
import { AuthContext } from "../../../context/auth";
import Error403 from "../../../components/Error/403";

export const ExternalInventoryAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    externalinventory_id: "",
    client_name: "",
    product_name: "",
    user_id: "",
    init_product_width: "",
    init_product_length: "",
    init_height: "",
    weight: "",
    price: "", // Add the price field
    quantity: "",
    category_id: "",
    status: "",
  });
  const { user } = useContext(AuthContext);

  const [totalPrice, setTotalPrice] = useState(0); // State for real-time total price
  const [lastFiveInventories, setLastFiveInventories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lengthUnit, setLengthUnit] = useState("mm");

  useEffect(() => {
    if (formData.weight && formData.price) {
      setTotalPrice(parseFloat(formData.weight) * parseFloat(formData.price));
    }
  }, [formData.weight, formData.price]);

  const fetchLastFiveInventories = async () => {
    try {
      const response = await axios.get("/get-all-account-inventory");
      setLastFiveInventories(response.data.slice(-5).reverse());
    } catch (error) {
      console.error("Error fetching last five inventories:", error);
    }
  };

  useEffect(() => {
    fetchLastFiveInventories();
    fetchCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "inventory");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Calculate the total price whenever weight or price changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Conversion factor
    const inchToMmFactor = 25.4;

    // Convert the values to mm if the unit is "Inch"
    let convertedData = { ...formData };
    if (lengthUnit === "Inch") {
      convertedData = {
        ...formData,
        init_product_length: formData.init_product_length * inchToMmFactor,
        init_product_width: formData.init_product_width * inchToMmFactor,
        init_height: formData.init_height * inchToMmFactor,
      };
    }

    try {
      const response = await axios.post("/add-account-inventory", {
        ...convertedData,
        user_id: loggedInUser,
        category_id: selectedCategory.value,
      });
      toast.success("Item added successfully");
      fetchLastFiveInventories();
      setFormData({
        externalinventory_id: "",
        client_name: "",
        product_name: "",
        init_product_width: "",
        init_product_length: "",
        init_height: "",
        weight: "",
        price: "", // Clear the price
        quantity: "",
        status: "",
      });
    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("Failed to add item");
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-all-account-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              <Link
                to="/account/inventory"
                className="btn btn-primary float-right"
              >
                See All Inventory
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Inventory</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="product_name">Product Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex- Alaxa White Block"
                          id="product_name"
                          name="product_name"
                          value={formData.product_name}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="weight">Product Weight (TON)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Weight"
                          id="weight"
                          name="weight"
                          value={formData.weight}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="price">Product Price (per TON)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Price"
                          id="price"
                          name="price"
                          value={formData.price}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="total_price">Total Price</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Total Price"
                          id="total_price"
                          value={totalPrice.toFixed(2)} // Display calculated total price
                          disabled
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <label htmlFor="init_product_length">
                          Product Length
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`Enter Product Length (${lengthUnit})`}
                          id="init_product_length"
                          name="init_product_length"
                          value={formData.init_product_length}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="init_product_width">
                          Product Width
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`Enter Product Width (${lengthUnit})`}
                          id="init_product_width"
                          name="init_product_width"
                          value={formData.init_product_width}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="init_height">Product Height</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ex- 50"
                          id="init_height"
                          name="init_height"
                          value={formData.init_height}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="client_name">Client Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Client Name"
                          id="client_name"
                          name="client_name"
                          value={formData.client_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="category_id">Category Name</label>
                        <Select
                          options={categories.map((category) => ({
                            value: category._id,
                            label: category.categoryName,
                          }))}
                          value={selectedCategory}
                          onChange={setSelectedCategory}
                          placeholder="Select Category"
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="quantity">Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Quantity"
                          id="quantity"
                          name="quantity"
                          value={formData.quantity}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="unit">Select Unit</label>
                        <Select
                          options={[
                            { value: "mm", label: "MM" },
                            { value: "Inch", label: "Inch" },
                          ]}
                          value={{ value: lengthUnit, label: lengthUnit }}
                          onChange={(selectedOption) =>
                            setLengthUnit(selectedOption.value)
                          }
                        />
                      </div>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Add Item
                    </button>
                  </form>
                </div>
                <div className="mt-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
